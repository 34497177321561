import { useEffect, useState } from "react";
import styled from "styled-components";

import PageWrapper from "./PageWrapper";
import LinkList from "./LinkList";
import TagList from "./TagList";
import ThumbnailList from "./ThumbnailList";

import lifesWork from "./lifes-work";

const Flex = styled.div`
  display: flex;
  align-items: start;
  gap: 80px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 40px;
  }
`;
const Hello = styled.h1`
  font-family: "Quicksand";
  font-weight: bold;
  font-size: 1.5em;

  @media (max-width: 800px) {
    font-size: 1.3em;
    br {
      display: none;
    }
  }
`;
const Artist = styled.p`
  font-size: 0.8em;
  margin-bottom: 60px;
  font-family: "Quicksand";
  a {
    text-decoration: underline;
    color: white;
    margin-left: 4px;
  }
`;

function Work() {
  const tags = [
    { tag: "client-work", color: "#fd79a8" },
    { tag: "product-company", color: "#F09B54" },
    { tag: "opensource", color: "#fdeb6e" },
    { tag: "personal-tracking", color: "#00cec9" },
    { tag: "curation", color: "#0984e3" },
    { tag: "prototype", color: "#6c5ce7" },
  ];

  const [yearProjects, setYearProjects] = useState([]);
  const [displayProjects, setDisplayProjects] = useState([]);
  const [displayTags, setDisplayTags] = useState(tags.map((t) => t.tag));

  const updateTag = (tag) => {
    let newTags = [...displayTags];
    if (displayTags.indexOf(tag) === -1) newTags.push(tag);
    else newTags.splice(displayTags.indexOf(tag), 1);

    setDisplayTags(newTags);
  };

  useEffect(() => {
    const years = [...new Set(lifesWork.map((l) => l.year))];

    const newYearProjects = years.map((y) => {
      const projects = lifesWork.filter((l) => l.year === y);
      return {
        title: y,
        projects,
      };
    });
    setYearProjects(newYearProjects);
    setDisplayProjects(newYearProjects);
  }, []);

  useEffect(() => {
    if (!yearProjects.length) return;

    let newDisplayProjects = [...yearProjects];
    newDisplayProjects = newDisplayProjects
      .map((t) => {
        let newProjects = [...t.projects];
        newProjects = newProjects.filter((p) => {
          // for each tag, look at each display tag and try to match
          return p.tags.some((r) => displayTags.includes(r));
        });
        return {
          title: t.title,
          projects: newProjects,
        };
      })
      .filter((t) => t.projects.length);

    setDisplayProjects(newDisplayProjects);
    // eslint-disable-next-line
  }, [displayTags]);

  const isWide = window.innerWidth > 800;

  return (
    <PageWrapper>
      <Hello>
        rachel binx is a creative technologist <br /> specializing in data
        visualization, mapping, and digital ↣ physical fabrication.
      </Hello>
      <Artist>
        (sometimes she is also an
        <a href="https://rachelbinx.art">artist</a>)
      </Artist>

      <TagList
        tags={tags}
        displayTags={displayTags}
        updateTag={updateTag}
        setTags={setDisplayTags}
      />
      <Flex>
        <LinkList list={displayProjects} tags={tags} />
        {isWide && <ThumbnailList list={displayProjects} />}
      </Flex>
    </PageWrapper>
  );
}

export default Work;
